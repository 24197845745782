import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Error from "@/views/error.vue";
import Index from "@/views/index.vue";
import Places from "@/views/places.vue";
import ImageGallery from "@/views/image-gallery.vue";
import Coupons from "@/views/coupons.vue";
import Maintenance from "@/views/maintenance.vue";
import { setLocale } from "@/plugins/i18n";

import {
    activateTicket,
    fetchTicketConfigByTicketIdHash,
    verifyNotification,
} from "@/api/enterprise-vacan.adapter.api";

import * as storage from "@/api/local-storage";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    { path: "/maintenance", name: "Maintenance", component: Maintenance },
    { path: "/error", name: "Error", component: Error },
    { path: "/:websiteId/error", name: "WebsiteError", component: Error, props: true },
    { path: "/:websiteId", name: "Index", component: Index, props: true },
    { path: "/:websiteId/places/:placeIdHash", name: "Place", component: Places, props: true },
    { path: "/:websiteId/places/:placeIdHash/images", name: "ImageGallery", component: ImageGallery, props: true },
    { path: "/:websiteId/places/:placeIdHash/coupons", name: "Coupons", component: Coupons, props: true },
    {
        path: "/:websiteId/places/:placeIdHash/qticket/tutorial",
        name: "QT/Tutorial",
        props: true,
        component: () => import(/* webpackChunkName: "qticket" */ "@/views/qticket/tutorial.vue"),
    },
    {
        path: "/:websiteId/places/:placeIdHash/entry",
        name: "QT/Entry",
        props: true,
        beforeEnter(to, _from, next) {
            if (!storage.get("SKIP_TUTORIAL") && !to.query["skip-tutorial"]) {
                return next({ name: "QT/Tutorial", params: to.params, query: to.query });
            }

            return next();
        },
        component: () => import(/* webpackChunkName: "qticket" */ "@/views/qticket/entry.vue"),
    },
    {
        path: "/:websiteId/places/:placeIdHash/confirmation",
        name: "QT/Confirmation",
        props: true,
        component: () => import(/* webpackChunkName: "qticket" */ "@/views/qticket/confirmation.vue"),
    },
    {
        path: "/:websiteId/places/:placeIdHash/completion",
        name: "QT/Completion",
        props: true,
        component: () => import(/* webpackChunkName: "qticket" */ "@/views/qticket/completion.vue"),
    },
    {
        path: "/:websiteId/places/:placeIdHash/conflicted",
        name: "QT/Conflicted",
        props: true,
        component: () => import(/* webpackChunkName: "qticket" */ "@/views/qticket/conflicted.vue"),
    },
    {
        path: "/:websiteId/places/:placeIdHash/exceeded",
        name: "QT/Exceeded/Prerequest",
        props: true,
        component: () => import(/* webpackChunkName: "qticket" */ "@/views/qticket/limit-exceeded.vue"),
    },
    {
        path: "/:websiteId/exceeded",
        name: "QT/Exceeded/Activation",
        props: true,
        component: () => import(/* webpackChunkName: "qticket" */ "@/views/qticket/limit-exceeded.vue"),
    },
    {
        path: "/tickets/:ticketIdHash",
        name: "QT/Ticket",
        props: true,
        component: () => import(/* webpackChunkName: "qticket" */ "@/views/qticket/tickets.vue"),
    },
    {
        path: "/tickets/:ticketIdHash/notifications",
        name: "QT/Notification",
        props: true,
        component: () => import(/* webpackChunkName: "qticket" */ "@/views/qticket/email-notification.vue"),
    },
    {
        path: "/tickets/:ticketIdHash/notifications/completed",
        name: "QT/Notification/Completed",
        props: true,
        component: () => import(/* webpackChunkName: "qticket" */ "@/views/qticket/email-notification-completed.vue"),
    },
    {
        path: "/tickets/:ticketIdHash/notifications/conflicted",
        name: "QT/Notification/Conflicted",
        props: true,
        component: () => import(/* webpackChunkName: "qticket" */ "@/views/qticket/email-notification-conflicted.vue"),
    },
    {
        path: "/tickets/:ticketIdHash/email/activation/verified",
        name: "QT/Notification/Verified",
        props: true,
        component: () => import(/* webpackChunkName: "qticket" */ "@/views/qticket/email-notification-verified.vue"),
    },
    {
        path: "/tickets/:ticketIdHash/email/activation/failed",
        name: "QT/Notification/Invalid",
        props: true,
        component: () => import(/* webpackChunkName: "qticket" */ "@/views/qticket/email-notification-invalid.vue"),
    },
    // verify email notification
    {
        path: "/tickets/:ticketIdHash/email/verify/:activationHash",
        beforeEnter: (to, _, next) => {
            const activationHash: string = to.params.activationHash;

            verifyNotification(activationHash)
                .then((result) => {
                    if (result === undefined || result.isActivated !== true || result.ticketIdHash === undefined) {
                        throw new Error();
                    }
                    next({
                        name: "QT/Notification/Verified",
                        params: { ticketIdHash: result.ticketIdHash },
                        replace: true,
                    });
                    return;
                })
                .catch(() => {
                    next({
                        name: "QT/Notification/Invalid",
                        params: to.params,
                        replace: true,
                    });
                });
        },
    },

    {
        // activate ticket for user from mobile
        path: "/join/:requestIdHash",
        beforeEnter: async (to, _, next) => {
            const hash: string = to.params.requestIdHash;

            try {
                const result = await activateTicket(hash);

                if (result === undefined || result.ticketIdHash === undefined) {
                    throw new Error();
                }

                const ticketConfig = await fetchTicketConfigByTicketIdHash(result.ticketIdHash);

                // FIXME: should not use local storage for this purpose
                if (ticketConfig.notificationTarget === "email") {
                    storage.set(`${result.ticketIdHash}/didUserSendEmail`, true);
                }

                next({
                    name: "QT/Ticket",
                    params: { ticketIdHash: result.ticketIdHash },
                    replace: true,
                });
                return;
            } catch (error) {
                // TODO: temporary implementation
                if (typeof error === "object" && error !== null && "status" in error && error.status === 422) {
                    const websiteId =
                        "data" in error && error.data != undefined ? (error.data as any).placeGroupIdHash : undefined;

                    next({
                        name: "QT/Exceeded/Activation",
                        params: {
                            websiteId,
                        },
                        replace: true,
                    });
                    return;
                }

                next({
                    name: "Error",
                    replace: true,
                });
            }
        },
    },
    // verify phone number for user from tablet
    {
        path: "/verify/:activationHash",
        beforeEnter: (to, _, next) => {
            const activationHash: string = to.params.activationHash;

            verifyNotification(activationHash)
                .then((result) => {
                    if (result === undefined || result.isActivated !== true || result.ticketIdHash === undefined) {
                        throw new Error();
                    }
                    next({
                        name: "QT/Ticket",
                        params: { ticketIdHash: result.ticketIdHash },
                        replace: true,
                    });
                    return;
                })
                .catch(() => next({ name: "Error" }));
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, _, next) => {
    if (typeof to.query.lang === "string") {
        setLocale(to.query.lang);
    }

    next();
});

export default router;
